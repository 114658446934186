import React, { Component, useEffect, useState } from 'react';
import Slider from 'react-slick';
import exampleBanner from '../../assets/images/banner-1.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import axios from 'axios';
import { getCookie } from '../../helpers/cookie';
import { arrow } from 'tailwind-hamburgers/types';

const Carousel = () => {
  const [banners, setBanners] = useState([]);
  const user = getCookie('user') && JSON.parse(getCookie('user'));

  useEffect(() => {
    getBanners();
    return () => {};
  }, []);

  const getBanners = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/banner`, {
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      });

      setBanners(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const settingsMultipleSlider = {
    // arrows: false,
    // dots: true,
    // className: 'center',
    // centerMode: true,
    // infinite: true,
    // centerPadding: '360px',
    // slidesToShow: 2,
    // lazyLoad: true,
    // speed: 2000,
    // autoplay: true,
    // autoplaySpeed: 2000,
    // cssEase: 'linear',
    centerMode: true,
    centerPadding: '360px',
    arrows: false,
    className: 'center',
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          arrows: false,
          // className: 'center',
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          autoplaySpeed: 2000,
          cssEase: 'linear',
          variableWidth: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: false,
          centerPadding: '0px',
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          speed: 500,
        },
      },
    ],
  };

  const settingSimpleSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // className: 'simple',
  };

  return (
    <div className="w-full">
      {banners.length === 1 ? (
        // <Slider {...settingSimpleSlider}>
        <div className="flex justify-center mt-0 sm:mt-[-50px] px-3 py-2">
          <a
            className="single-banner w-full sm:w-[92%] h-[180px] sm:h-[280px]"
            href={banners[0].link}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-full h-full rounded-xl"
              src={banners[0].path}
              alt={banners[0].name}
            />
          </a>
        </div>
      ) : (
        // </Slider>
        <Slider {...settingsMultipleSlider}>
          {banners.map((banner) => {
            return (
              <div key={banner.id}>
                <a href={banner.link} target="_blank" rel="noreferrer">
                  <img className="w-full" src={banner.path} alt={banner.name} />
                </a>
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default Carousel;
